.header_text {
    position: relative;
    margin-top: 4rem;
}
.header_text::after, .header_text::before {
    position: absolute;
    content: "";
    background-color: #0dbad7;
    width: 2em;
    height: 0.07em;
    top: 43%;
}
.header_text::before {
    left: -5rem;
}
.header_text::after {
    right: -5rem;
}

@media screen and (max-width: 600px) {
    .header_text {
        margin-top: 2rem;
    }
    .header_text::before {
        left: -4rem;
    }
    .header_text::after {
        right: -4rem;
    }
}