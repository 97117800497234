#App {
  font-family: sans-serif;
  transition: color 1s;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;
  outline: none;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 5px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  margin-left: 5px;
  position: relative;
  width: 20px;
  height: 15px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #0498aec7;
  height: 2px !important;
  border-radius: 2px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.3em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  margin-left: -10px;
}

.bm-menu-wrap {
  width: 270px !important;
  margin-left: -10px;
  border-right: 5px solid #3b3c4e47;
  height: 100vh !important;
}

.menu_item {
  font-size: 20px;
  width: 220px;
  text-align: left !important;
  padding: 12px;
}

.menu_croxprice {
  outline: hidden;
  box-shadow: none;
  display: flex;
  justify-content: center;
  padding: 0 40px 18px;
}

.croxprice {
  margin-top: -20px;
  padding: 10px 0;
  border-radius: 15px;
  text-align: center;
  background-color: #3B3C4E;
}

.menu_active {
  background-color: #3b3c4e47;
  border-radius: 10px;
}

.white_mode_price {
  background-color: #0498aec7;
}

.menu_active_white {
  background-color: white;
  border-radius: 10px;
}